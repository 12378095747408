import { useEffect, useState } from "react";
import { Claim } from "../interface/Claim";
import { Box, Container, Paper, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

export const ClaimDetails = () => {
  //useParams by react-router-dom
  const { id } = useParams();

  const [claimDetails, setClaimDetails] = useState<Claim | null>(null);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchClaimDetails = async () => {
      const url: string =  `/api/claim/${id}/details`;
      const response = await fetch(url);
      const data = await response.json();
      setClaimDetails(data);
      setLoading(false);
    };

    fetchClaimDetails().catch((error: any) => {
      setLoading(false);
      setError(error);
    });
  }, [id]);

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
        {claimDetails? (
          <Box sx={{ mt: 3, textAlign: "left" }}>
          <Typography variant="h5" gutterBottom>Claim Details</Typography>
          <Typography variant="body2">CaseID: {claimDetails.caseId}</Typography>
          <Typography variant="body2">Status: {claimDetails.status}</Typography>
          <Typography variant="body2">Date: {claimDetails.date}</Typography>
          <Typography variant="body2">Lawyer Feedback: {claimDetails.lawyerFeedback}</Typography>
          <Typography variant="body2">Reason: {claimDetails.reason}</Typography>
          <Typography variant="body2">Amount: {claimDetails.amount}</Typography>
          <Typography variant="body2">Contant Permission: {claimDetails.contactPermission}</Typography>
          <Typography variant="h6">Tenant Details</Typography>
          <Typography variant="body2">Name: {claimDetails.tenant.name}</Typography>
          <Typography variant="body2">Street: {claimDetails.tenant.street}</Typography>
          <Typography variant="body2">Pincode: {claimDetails.tenant.pinCode}</Typography>
          <Typography variant="body2">City: {claimDetails.tenant.city}</Typography>
          <Typography variant="body2">Country: {claimDetails.tenant.country}</Typography>
          <Typography variant="body2">Email: {claimDetails.tenant.email}</Typography>
          <Typography variant="body2">Phone: {claimDetails.tenant.phone}</Typography>
          <Typography variant="h6">Landlord Details</Typography>
          <Typography variant="body2">Name: {claimDetails.landLord.name}</Typography>
          <Typography variant="body2">Street: {claimDetails.landLord.street}</Typography>
          <Typography variant="body2">Pincode: {claimDetails.landLord.pinCode}</Typography>
          <Typography variant="body2">City: {claimDetails.landLord.city}</Typography>
          <Typography variant="body2">Country: {claimDetails.landLord.country}</Typography>
          <Typography variant="body2">Email: {claimDetails.landLord.email}</Typography>
          <Typography variant="body2">Phone: {claimDetails.landLord.phone}</Typography>
          <Typography variant="h6">Contract Details</Typography>
          <Typography variant="body2">Deposit Amount: {claimDetails.contract.depositAmount}</Typography>
          <Typography variant="body2">Address: {claimDetails.contract.address}</Typography>
        </Box>
        ) : (
          <Box>
            Loading ...
          </Box>
        )}
      </Paper>
    </Container>
  );
};

