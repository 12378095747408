import {
    Box,
    Card,
    CardMedia,
    Container,
    List,
    ListItem,
    Paper,
    Typography,
  } from "@mui/material";
  
  export const HomePage = () => {
    return (
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
          <Typography variant="h4" sx={{ fontWeight: "bold" }} gutterBottom>
            Welcome to Legal Case Management
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" gutterBottom>
            Streamline your legal process with ease. Submit claims, track
            progress, and get support from our legal team.
          </Typography>
          <Container maxWidth="md" sx={{ mt: 4, mb: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>
              How it Works
            </Typography>
            <Typography variant="body1" align="left" gutterBottom>
              1. Submit your claim with necessary details.
            </Typography>
            <Typography variant="body1" align="left" gutterBottom>
              2. Our team will review the claim and provide updates.
            </Typography>
            <Typography variant="body1" align="left" gutterBottom>
              3. You can track your claim status online.
            </Typography>
            <Typography variant="body1" align="left" gutterBottom>
              4. Lawyers will provide feedback.
            </Typography>
            <Typography variant="body1" align="left" gutterBottom>
              5. Resolve disputes efficiently.
            </Typography>
  
            <Card sx={{ maxWidth: 400, mt: 4 }}>
              <CardMedia
                component="iframe"
                src="https://www.youtube.com/embed/fJ9rUzIMcZQ"
                title="Youtube video player"
                style={{ height: 200 }}
              />
            </Card>
          </Container>
  
          <Box sx={{ mt: 4, mb: 2 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>
              Key Statistics
            </Typography>
            <Typography variant="body1" align="left" paragraph>
              Landlord and tenant disputes in Germany are quite common, but the
              country offers strong legal protections for tenants. Below are some
              of the key statistics and trends:
            </Typography>
  
            <List>
              <ListItem>
                <Typography variant="body1">
                  <Typography component="span" fontWeight="bold">
                    Tenant Protections :-
                  </Typography>
                  The German rental system is heavily regulated, providing strong
                  protections for tenants. Rent control mechanisms like the
                  "Mietpreisbremse" (rent brake) are in place to limit rent
                  increases in areas with high demand for housing, ensuring
                  fairness and stability for tenants in cities like Berlin and
                  Munich.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  <Typography component="span" fontWeight="bold">
                    Eviction and Termination :-
                  </Typography>
                  Disputes often arise around lease terminations. Landlords must
                  justify evictions with substantial reasons, such as personal use
                  (Eigenbedarf) or repeated violations of contract terms. Despite
                  this, the legal framework makes evictions difficult without a
                  proven case.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  <Typography component="span" fontWeight="bold">
                    Security of Tenure :-
                  </Typography>
                  Although eviction rules are strict, landlords may try to reclaim
                  properties for personal use. However, tenants are protected from
                  arbitrary evictions under German law, which requires a justified
                  legal process.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  <Typography component="span" fontWeight="bold">
                    Frequent Disputes :-
                  </Typography>
                  Common disputes include issues with unpaid rent, property
                  damage, or the responsibility for maintenance. These often
                  require legal intervention, either through mediation or court
                  proceedings.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  <Typography component="span" fontWeight="bold">
                    Dispute Resolution :-
                  </Typography>
                  Germany offers effective legal recourse for resolving disputes.
                  Tenants can seek advice and support from tenant associations
                  like the "Mieterverein," which help protect tenant rights in
                  cases of rent increases or illegal evictions.
                </Typography>
              </ListItem>
            </List>
          </Box>
        </Paper>
      </Container>
    );
  };
  