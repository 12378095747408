import React, { useState, useEffect } from "react";
import { Claim } from "../../interface/Claim";
import {
  Box,
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

export const ClaimList = () => {
  const navigate = useNavigate();
  const [claims, setClaims] = useState<Claim[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchClaims = async () => {
      const url: string = `/api/claim`;
      const response = await fetch(url);
      const data = await response.json();

      //setClaims(data);
      setClaims(Array.isArray(data) ? data : []);
      setLoading(false);
    };

    fetchClaims().catch((error: any) => {
      setLoading(false);
      setError(error);
    });
  }, []);

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
        <Box sx={{ padding: 4 }}>
          <Typography variant="h6" gutterBottom>
            All Submitted Claims
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Case ID</TableCell>
                  <TableCell>Date Submitted</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Tenant Name</TableCell>
                  <TableCell align="center">View Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(claims) && claims.length > 0 ? (
                  claims.map((claim) => (
                    <TableRow key={claim.id}>
                      <TableCell>{claim.id}</TableCell>
                      <TableCell>{claim.date}</TableCell>
                      <TableCell>{claim.status}</TableCell>
                      <TableCell>{claim.tenant?.name || "N/A"}</TableCell>
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => navigate(`/claimdetails/${claim.id}`)}
                        >
                          View Details
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No claims found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </Container>
  );
};
