import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { Claim } from "../../interface/Claim";

export const ViewClaim = () => {
  const [claimId, setClaimId] = useState("");
  const [email, setEmail] = useState("");
  const [claimDetails, setClaimDetails] = useState<Claim | null>(null);
  const [error, setError] = useState("");

  const handleSubmit = async () => {
    setError("");
    setClaimDetails(null);
    try {
      const response = await axios.get(`/api/claim/${claimId}`, {
        params: { email },
      });
      setClaimDetails(response.data);
    } catch (error) {
      setError("Error fetching claim details. Please check your inputs.");
      console.error(error);
    }
  };

  if(error) return(<Typography variant="body1" sx={{ color: "red", mt: 3 }}>{error}</Typography>);

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
        {claimDetails ? (
          <Box sx={{ mt: 3, textAlign: "left" }}>
            <Typography variant="h5" gutterBottom>Claim Details</Typography>
            <Typography variant="body2">CaseID: {claimDetails.caseId}</Typography>
            <Typography variant="body2">Status: {claimDetails.status}</Typography>
            <Typography variant="body2">Date: {claimDetails.date}</Typography>
            <Typography variant="body2">Lawyer Feedback: {claimDetails.lawyerFeedback}</Typography>
            <Typography variant="body2">Reason: {claimDetails.reason}</Typography>
            <Typography variant="body2">Amount: {claimDetails.amount}</Typography>
            <Typography variant="body2">Contant Permission: {claimDetails.contactPermission}</Typography>
            <Typography variant="h6">Tenant Details</Typography>
            <Typography variant="body2">Name: {claimDetails.tenant.name}</Typography>
            <Typography variant="body2">Street: {claimDetails.tenant.street}</Typography>
            <Typography variant="body2">Pincode: {claimDetails.tenant.pinCode}</Typography>
            <Typography variant="body2">City: {claimDetails.tenant.city}</Typography>
            <Typography variant="body2">Country: {claimDetails.tenant.country}</Typography>
            <Typography variant="body2">Email: {claimDetails.tenant.email}</Typography>
            <Typography variant="body2">Phone: {claimDetails.tenant.phone}</Typography>
            <Typography variant="h6">Landlord Details</Typography>
            <Typography variant="body2">Name: {claimDetails.landLord.name}</Typography>
            <Typography variant="body2">Street: {claimDetails.landLord.street}</Typography>
            <Typography variant="body2">Pincode: {claimDetails.landLord.pinCode}</Typography>
            <Typography variant="body2">City: {claimDetails.landLord.city}</Typography>
            <Typography variant="body2">Country: {claimDetails.landLord.country}</Typography>
            <Typography variant="body2">Email: {claimDetails.landLord.email}</Typography>
            <Typography variant="body2">Phone: {claimDetails.landLord.phone}</Typography>
            <Typography variant="h6">Contract Details</Typography>
            <Typography variant="body2">Deposit Amount: {claimDetails.contract.depositAmount}</Typography>
            <Typography variant="body2">Address: {claimDetails.contract.address}</Typography>
          </Box>
        ) : ( 
          <Box component="form">
            <Typography variant="h5" component="h1">
              View Your Claim
            </Typography>
            <Typography variant="h6" align="left">
              Case ID:
            </Typography>
            <TextField
              margin="normal"
              fullWidth
              label="CaseId"
              onChange={(e) => setClaimId(e.target.value)}
              required
            />
            <Typography variant="h6" align="left">
              Email:
            </Typography>
            <TextField
              margin="normal"
              fullWidth
              label="Email"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <Button variant="contained" onClick={handleSubmit} sx={{ mt: 2 }}>
              Submit
            </Button>
          </Box>
        )}
      </Paper>
    </Container>
  );
};

