import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

// Define types for state
interface TenantLandlordDetails {
  name: string;
  street: string;
  pinCode: string;
  city: string;
  country: string;
  email: string;
  phone: string;
}

interface ContractDetails {
  depositAmount: string;
  address: string;
}

interface AdditionalDetails {
  reason: string;
  amount: string;
  contactPermission: string;
}

interface Errors {
  tenantDetails: Partial<Record<keyof TenantLandlordDetails, string>>;
  landlordDetails: Partial<Record<keyof TenantLandlordDetails, string>>;
  contractDetails: Partial<Record<keyof ContractDetails, string>>;
  additionalDetails: Partial<Record<keyof AdditionalDetails, string>>;
}

export const NewClaim: React.FC = () => {
  const [claimId, setClaimId] = useState<string | null>(null);
  const [tenantDetails, setTenantDetails] = useState<TenantLandlordDetails>({
    name: "",
    street: "",
    pinCode: "",
    city: "",
    country: "",
    email: "",
    phone: "",
  });
  const [landlordDetails, setLandlordDetails] = useState<TenantLandlordDetails>(
    {
      name: "",
      street: "",
      pinCode: "",
      city: "",
      country: "",
      email: "",
      phone: "",
    }
  );
  const [contractDetails, setContractDetails] = useState<ContractDetails>({
    depositAmount: "",
    address: "",
  });
  const [additionalDetails, setAdditionalDetails] = useState<AdditionalDetails>(
    {
      reason: "",
      amount: "",
      contactPermission: "",
    }
  );
  const [errors, setErrors] = useState<Errors>({
    tenantDetails: {},
    landlordDetails: {},
    contractDetails: {},
    additionalDetails: {},
  });

  useEffect(() => {
    const createDraftClaim = async () => {
      try {
        const response = await axios.post("/api/claim", {});
        setClaimId(response.data.id);
      } catch (error) {
        console.error("Error creating draft claim: ", error);
      }
    };
    createDraftClaim();
  }, []);

  const validateField = (
    section: keyof Errors,
    field: string,
    value: string
  ): boolean => {
    let error = "";
    switch (field) {
      case "name":
      case "street":
      case "city":
      case "country":
      case "reason":
      case "contactPermission":
        if (!value.trim()) error = "This field is required.";
        break;

      case "pinCode":
        if (!/^\d{6}$/.test(value)) error = "Pin Code must be 6 digits.";
        break;

      case "email":
        if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value))
          error = "Enter a valid email address.";
        break;

      case "phone":
        if (!/^\d{12}$/.test(value)) error = "Phone number must be 12 digits.";
        break;

      case "depositAmount":
      case "amount":
        if (isNaN(Number(value)) || Number(value) <= 0)
          error = "Enter a valid positive number.";
        break;

      case "address":
        if (!value.trim()) error = "Address is required.";
        break;

      default:
        break;
    }
    setErrors((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: error,
      },
    }));
    return !error;
  };

  const handleFieldChange = (
    section: keyof Errors,
    field: string,
    value: string
  ) => {
    const updateState = {
      tenantDetails: setTenantDetails,
      landlordDetails: setLandlordDetails,
      contractDetails: setContractDetails,
      additionalDetails: setAdditionalDetails,
    }[section];

    updateState((prev: any) => ({ ...prev, [field]: value }));
    validateField(section, field, value);
  };

  const handleSubmit = async () => {
    let isValid = true;
    [
      "tenantDetails",
      "landlordDetails",
      "contractDetails",
      "additionalDetails",
    ].forEach((section) => {
      Object.entries(eval(section)).forEach(([field, value]) => {
        if (!validateField(section as keyof Errors, field, value as string)) {
          isValid = false;
        }
      });
    });

    if (!isValid) {
      alert("Please fix the errors before submitting.");
      return;
    }

    try {
      await axios.post(`/api/claim/${claimId}/tenant`, tenantDetails);
      await axios.post(`/api/claim/${claimId}/landlord`, landlordDetails);
      await axios.post(`/api/claim/${claimId}/contract`, contractDetails);
      await axios.put(`/api/claim/${claimId}`, additionalDetails);
      alert(`Claim submitted with Claim Id: ${claimId}`);
    } catch (error) {
      console.error("Error submitting forms: ", error);
      alert("Failed to submit one or more forms.");
    }
  };

  return (
    <Container component="main" maxWidth="md">
      <Paper elevation={3} sx={{ p: 4, mb: 4, mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          Submit new Claim
        </Typography>

        {claimId ? (
          <Box component="form" sx={{ mt: 3 }}>
            <Typography variant="h5">Tenant Details</Typography>
            {Object.keys(tenantDetails).map((field) => (
              <TextField
                key={field}
                margin="normal"
                required
                fullWidth
                label={field.charAt(0).toUpperCase() + field.slice(1)}
                name={field}
                value={(tenantDetails as any)[field]}
                error={
                  !!errors.tenantDetails[field as keyof TenantLandlordDetails]
                }
                helperText={
                  errors.tenantDetails[field as keyof TenantLandlordDetails]
                }
                onChange={(e) =>
                  handleFieldChange("tenantDetails", field, e.target.value)
                }
              />
            ))}

            <Typography variant="h5">Landlord Details</Typography>
            {Object.keys(landlordDetails).map((field) => (
              <TextField
                key={field}
                margin="normal"
                required
                fullWidth
                label={field.charAt(0).toUpperCase() + field.slice(1)}
                name={field}
                value={(landlordDetails as any)[field]}
                error={
                  !!errors.landlordDetails[field as keyof TenantLandlordDetails]
                }
                helperText={
                  errors.landlordDetails[field as keyof TenantLandlordDetails]
                }
                onChange={(e) =>
                  handleFieldChange("landlordDetails", field, e.target.value)
                }
              />
            ))}

            <Typography variant="h5">Contract Details</Typography>
            {Object.keys(contractDetails).map((field) => (
              <TextField
                key={field}
                margin="normal"
                required
                fullWidth
                label={field.charAt(0).toUpperCase() + field.slice(1)}
                name={field}
                value={(contractDetails as any)[field]}
                error={!!errors.contractDetails[field as keyof ContractDetails]}
                helperText={
                  errors.contractDetails[field as keyof ContractDetails]
                }
                onChange={(e) =>
                  handleFieldChange("contractDetails", field, e.target.value)
                }
              />
            ))}

            <Typography variant="h5">Additional Details</Typography>
            {Object.keys(additionalDetails).map((field) => (
              <TextField
                key={field}
                margin="normal"
                required
                fullWidth
                label={field.charAt(0).toUpperCase() + field.slice(1)}
                name={field}
                value={(additionalDetails as any)[field]}
                error={
                  !!errors.additionalDetails[field as keyof AdditionalDetails]
                }
                helperText={
                  errors.additionalDetails[field as keyof AdditionalDetails]
                }
                onChange={(e) =>
                  handleFieldChange("additionalDetails", field, e.target.value)
                }
              />
            ))}

            <Button
              type="button"
              onClick={handleSubmit}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit
            </Button>
          </Box>
        ) : (
          <Typography variant="body1">Loading...</Typography>
        )}
      </Paper>
    </Container>
  );
};
