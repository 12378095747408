import React from "react";
import "./App.css";
import { NotFound } from "../NotFound";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { NewClaim } from "../NewClaim";
import { ViewClaim } from "../ViewClaim";
import { HomePage } from "../HomePage";
import { Navbar } from "../Navbar";
import { ClaimList } from "../ClaimList";
import { Tp } from "../../components/Tp";
import { ClaimDetails } from "../../components/ClaimDetails";


function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/home" element={<HomePage />} />
          <Route path="/newclaim" element={<NewClaim />} />
          <Route path="/tenantlogin" element={<ViewClaim />} />
          <Route path="/lawyerlogin" element={<ClaimList />} />
          <Route path="/claimdetails/:id" element={<ClaimDetails/>} />
          <Route path="*" element={<HomePage/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
