import { AppBar, Box, Button, Toolbar, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const Navbar = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ bgcolor: "#4c4c4c" }}>
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            sx={{ flexGrow: 1, textAlign: "left", ml: 2 }}
          >
            Legal Case Management
          </Typography>

          <Button
            color="inherit"
            // variant="outlined"
            onClick={() => navigate("/home")}
            // sx={{ ml: 2 }}
          >
            Home
          </Button>
          <Button
            color="inherit"
            // variant="outlined"
            onClick={() => navigate("/newclaim")}
            // sx={{ ml: 2 }}
          >
            Submit New Claim
          </Button>
          <Button
            color="inherit"
            // variant="outlined"
            onClick={() => navigate("/tenantlogin")}
            sx={{ mr: 2 }}
          >
            View Existing Claim
          </Button>
          <Button
            color="inherit"
            // variant="outlined"
            onClick={() => navigate("/lawyerlogin")}
            sx={{ mr: 2 }}
          >
            Lawyer Login
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
